import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    last: String,
    apiPath: String,
    page: Number,
    finished: Boolean
  }
  static targets = ['list', 'turbo', 'more', 'form', 'page']

  connect() {
    this.unbindEventListener()
    this.bindEventListener()
    process.nextTick(() => {
      this.bindObserver()
    })
  }

  disconnect() {
    this.unbindEventListener()
  }

  bindEventListener() {
    if (!!!this.observer) {
      this.observer = new IntersectionObserver(([entrie]) => {
        if (!entrie.isIntersecting) return;
        this.loadNextPageNewsList()
      }, {
        rootMargin: "10px 0px 3px 5px",
        threshold: 0
      })
    }
  }

  unbindEventListener() {
    this.observer?.disconnect()
  }

  bindObserver() {
    if (!!!this.listTarget.querySelector('.news-box-list--more')) {
      setTimeout(() => this.bindObserver(), 50)
      return
    }
    this.observer?.observe(this.listTarget.querySelector('.news-box-list--more'))
  }

  loadNextPageNewsList () {
    if (this.finishedValue) {
      return
    }

    if (this.listTarget.querySelector('.news-page-loading')) {
      return
    }

    if (!!this.listTarget.querySelector('.news-box-list--more')) {
      this.listTarget.querySelector('.news-box-list--more').remove()

      this.pageValue = this.pageValue + 1
      this.listTarget.innerHTML += `<div class="news-page-loading"></div>`

      showLoadingIndicator()
      this.pageTarget.value = this.pageValue
      this.formTarget.requestSubmit()
      this.turboTarget.loaded.then(() => {
        hideLoadingIndicator()
        if (!!this.listTarget.querySelector('.news-no-data')) {
          this.finishedValue = true
          return
        }

        this.listTarget.querySelector('.news-page-loading').remove()
        process.nextTick(() => {
          this.bindObserver()
        })
      })
    }
  }
}
