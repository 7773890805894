import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper';
import { Navigation, Pagination, FreeMode, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class extends Controller {
  static targets = ['activeItem']
  connect() {
    this.Swiper = new Swiper('.tab-slider-container', {
      modules: [Navigation, Pagination, FreeMode, Mousewheel],
      slidesPerView: "auto",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      mousewheel: true,
      initialSlide: this.activeItem()
    });
  }

  activeItem() {
    if (!this.hasActiveItemTarget) {
      return 0
    }

    return parseInt(this.activeItemTarget.dataset.index) - 1
  }
}
