import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    newsId: String
  }
  static targets = ['clusters', 'clusterToggle', 'link']

  connect() {
    this.addLinkClickEvent()
  }

  toggleSiblings () {
    this.clusterToggleTarget.classList.toggle('active')
    this.clustersTarget.classList.toggle('active')

    ga_event_js('관련뉴스 클릭', { news_id: this.newsIdValue })
  }

  addLinkClickEvent () {
    this.linkTargets.forEach(link => {
      link.addEventListener('click', () => { ga_event_js('뉴스 클릭', { news_id: this.newsIdValue }) })
    })
  }
}
