// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers/application"
import "./loading_indicator"

window.process = {
  nextTick(fun) {
    return setTimeout(fun, 0);
  }
}
