// Initialize
// Turbo.setProgressBarDelay(500);
window.sessionStorage.removeItem('show-loading-indicator');

// Overwrite
// ref: https://github.com/hotwired/turbo/blob/main/src/core/drive/progress_bar.ts
// ref: https://github.com/hotwired/turbo/blob/main/src/core/native/browser_adapter.ts#L14
const ProgressBarProto = Turbo.session.adapter.progressBar.__proto__;
const Adapter = Turbo.session.adapter;

ProgressBarProto.installStylesheetElement = function () { }

// fast fadeout when call hideLoadingIndicator (default hide delay is 450ms)
ProgressBarProto.fadeProgressElement = function(callback) {
  setTimeout(callback, 10/*ms*/)
}

ProgressBarProto.installProgressElement = function() {
  window.sessionStorage.setItem('show-loading-indicator', 'true');

  const indicator_gif = document.createElement('div')
  indicator_gif.classList.add('loading-indicator-gif')

  const indicator_text = document.createElement('div')
  indicator_text.classList.add('loading-indicator-text')
  indicator_text.textContent = '로딩중 입니다.'

  const indicator = document.createElement('div')
  indicator.classList.add('loading-indicator')
  indicator.append(indicator_gif)
  indicator.append(indicator_text)

  const progress_bar = document.createElement('div')
  progress_bar.classList.add('turbo-progress-bar')
  progress_bar.dataset.turboCache = 'false'
  progress_bar.append(indicator)

  document.querySelector('body').prepend(progress_bar)
}

ProgressBarProto.uninstallProgressElement = function() {
  window.sessionStorage.removeItem('show-loading-indicator');

  if(!!document.querySelector('body').querySelector('.turbo-progress-bar')) {
    document.querySelector('body').querySelector('.turbo-progress-bar').remove()
  }
}

ProgressBarProto.refresh = function () {
  const self = this;
  return requestAnimationFrame(function (t) { // t.value: 0 ~> 1
    return function () {
      // remove cached loading indicator case when history back
      if (self.visible && window.sessionStorage.getItem('show-loading-indicator') !== 'true') {
        clearLoadingIndicator();
      }
    };
  }(this));
}

window.showLoadingIndicator = function(delay = true) {
  Adapter.progressBar.setValue(0);
  if (delay) {
    Adapter.showVisitProgressBarAfterDelay();
  }
  else {
    Adapter.showProgressBar();
  }
}

// 페이지가 이동되며 progress-bar가 정리되므로 직접 컨트롤하는 상황이 아니면 호출안해도 됨
window.hideLoadingIndicator = function() {
  Adapter.progressBar.setValue(1);
  Adapter.hideVisitProgressBar();
}

/*
 다음 상황 대응
 1. javascript에서 $('#form').submit() 으로 페이지 이동
   - turbo에서 감지를 못함
   - .turbo-progress-bar가 DOM에 추가됨
 2. 페이지 이동 완료
   - 새로 이동한 상태이므로 turbo:load 발생
   - .turbo-progress-bar는 삭제된 상태
 3. 뒤로가기 버튼으로 다시 돌아옴
   - DOM, js 변수 상태의 restore가 발생
   - turbo에서 감지되지 않고 어떤 이벤트도 발생하지 않는다
   - .turbo-progress-bar는 restore 되며 화면을 가림
   - turbo.controller.adapter.progressBar의 모든 변수가 살아나서 로딩이 그대로 돔
 대책
   - init, load, render 발생시 무조건 progress-bar를 삭제 (화면을 계속 가리고 있는 상태가 더 문제)
   - sessionStorage를 통해 browser, DOM 상태 상관없이 progress 상태를 관리
 */
const clearLoadingIndicator = function() {
  Adapter.hideVisitProgressBar();
  Adapter.progressBar.uninstallProgressElement()
  Adapter.progressBar.stopTrickling()

  Adapter.progressBar.visible = false
  Adapter.progressBar.hiding = false
}

window.addEventListener('turbo:load', function() {
  clearLoadingIndicator();
});
